const genLayoutLightStyle = (token) => {
    const { componentCls, bodyBg, lightSiderBg, lightTriggerBg, lightTriggerColor } = token;
    return {
        [`${componentCls}-sider-light`]: {
            background: lightSiderBg,
            [`${componentCls}-sider-trigger`]: {
                color: lightTriggerColor,
                background: lightTriggerBg,
            },
            [`${componentCls}-sider-zero-width-trigger`]: {
                color: lightTriggerColor,
                background: lightTriggerBg,
                border: `1px solid ${bodyBg}`,
                borderInlineStart: 0,
            },
        },
    };
};
export default genLayoutLightStyle;
